<template>
     <div class="root">
       <el-scrollbar  class="side scroll-page">
         <div  :class="[currentType=='ALL'?'menuSelected':'menu']" style="margin-top: 3rem" @click="selectType('ALL',null)">
           <img class="icon" src="../images/ic_drawer_home.png"/>
           <div class="label">全部</div>
           <div class="planNum" v-show="planNumAll!==0">{{planNumAll}}</div>
         </div>
         <div  :class="[currentType=='TODAY'?'menuSelected':'menu']" @click="selectType('TODAY',null)">
           <img class="icon" src="../images/ic_drawer_today.png"/>
           <div class="label">今天</div>
           <div class="planNum" v-show="planNumToday!==0">{{planNumToday}}</div>
         </div>
         <div class="divider"></div>
         <div  :class="[currentType=='UN_CATALOGED'?'menuSelected':'menu']"  @click="selectType('UN_CATALOGED',null)">
           <img class="icon" src="../images/ic_drawer_list.png"/>
           <div class="label">待办箱</div>
           <div class="planNum"  v-show="planNumUnCataloged!==0">{{planNumUnCataloged}}</div>
         </div>
         <div  v-for="(item, index) in collectionList" :key="index">
           <div :class="[(currentType=='COLLECTION'&&currentCollection.id==item.id)?'menuSelected':'menu']"
                @click="selectType('COLLECTION',item)">
             <img class="icon" src="../images/ic_select_collection.png"/>
             <div class="label">{{item.collectionName}}</div>
             <div class="planNum"  v-show="item.unDoneNum!==0">{{item.unDoneNum}}</div>
           </div>
         </div>
       </el-scrollbar>
       <div class="user">
         <el-popover
           placement="top"
           width="160"
           v-model="userPopover">
           <div  class="user_content">
             <div class="user_content_item">
               <div>网页端其他功能正在积极开发中，有任何问题可以在App最后一栏的客服和我们反馈<br/>感谢您的支持Thanks♪(･ω･)ﾉ</div>
             </div>
             <div class="user_content_item">
             </div>
             <div class="user_content_item">
             </div>
             <div class="user_content_item">
             </div>
             <div class="user_content_item" @click="signOut()">
               <div class="user_content_padding_left"></div>
               <div>退出登录</div>
             </div>
           </div>
           <div slot="reference"  class="user_ref">
             <img class="userPhoto" :src="userPhoto"/>
             <div>{{user.nickName}}</div>
           </div>
         </el-popover>
       </div>
     </div>
</template>

<script>
import { EventBus } from '@/config/eventBus';
import dayjs from 'dayjs'
import storage from '@/store/storage';
export default {
  data () {
    return {
      collectionList: [],
      planNumAll: 0,
      planNumToday: 0,
      planNumUnCataloged: 0,
      user: storage.get('user'),
      userPhoto: '',

      userPopover: false
    }
  },
  computed: {
    currentType () {
      return this.$store.getters.currentType
    },
    currentCollection () {
      return this.$store.getters.currentCollection
    }
  },
  created () {
    this.sideRefresh()
    EventBus.$on('sideRefresh', this.sideRefresh);

    if (!this.user.userPhoto.startsWith('http')) {
      this.userPhoto = 'http://srimg.gaokaocal.com/' + this.user.userPhoto
    } else {
      this.userPhoto = this.user.userPhoto
    }
  },
  beforeDestroy () {
    // 组件销毁前需要解绑事件。否则会出现重复触发事件的问题
    EventBus.$off('sideRefresh', this.sideRefresh);
  },
  methods: {
    async sideRefresh (param) {
      // console.log('sideRefresh')
      await this.db.planCollection.where({ isDeleted: 0 }).sortBy('sortSelf').then((queryList) => {
        this.collectionList = queryList
      })
      await this.db.plan.where({ isDone: 0, isDeleted: 0 }).sortBy('startDate').then((queryPlan) => {
        this.planNumAll = queryPlan.length

        // 计算 今天 未完成
        this.planNumToday = 0
        queryPlan.forEach(item => {
          if (item.startDate != null && item.startDate !== '') {
            let today = dayjs().format('YYYY-MM-DD')
            let diff = dayjs(item.startDate).diff(dayjs(today), 'day')
            if (diff === 0) {
              this.planNumToday++
            }
          }
        }
        )
        // 计算 待办箱 未完成
        this.planNumUnCataloged = 0
        queryPlan.forEach(item => {
          if (item.collectionID == null || item.collectionID === '') {
            this.planNumUnCataloged++
          }
        })

        // 计算 各个collection 未完成
        this.collectionList.forEach(collection => {
          let unDoneNum = 0
          queryPlan.forEach(item => {
            if (item.collectionID != null && item.collectionID === collection.id) {
              unDoneNum++
            }
          })
          collection.unDoneNum = unDoneNum
        })
      })
    },
    selectType (type, collection) {
      this.$store.dispatch('SET_CURRENT_TYPE', type)
      this.$store.dispatch('SET_CURRENT_COLLECTION', collection)
    },
    signOut () {
      this.db.plan.clear()
      this.db.planCollection.clear()
      storage.remove('user')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-page {
  overflow: hidden;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.root{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background: #fafafa;
}

.side {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}
.menu{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

}
.menu:hover{
  background: #eeeeee;
  cursor: pointer;
}
.menuSelected{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background: #eeeeee;
}
.menuSelected:hover{
  background: #eeeeee;
  cursor: pointer;
}

.icon{
  width: 1.35rem;
  padding-left: 10%;

}
.label{
  font-size: 1rem;
  //color: #505f79;
  color: #333333;
  margin-left: 6%;
  flex-grow: 1;
}
.planNum{
  font-size: 0.8rem;
  color: #999999;
  margin-right: 6%;
}
.divider{
  height: 0.8rem;
}
.user_ref{
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  color: #757575;
  font-size: 0.85rem;
}
.user_ref:hover{
  cursor: pointer;
}

.userPhoto{
  width: 2rem;
  border-radius:100px;
  margin-right:0.5rem;
  margin-left: 1rem;
}

.user_content{
  display: flex;
  flex-direction: column;
}
.user_content_item{
  display: flex;
  align-items: center;
  padding: 0.35rem 0.35rem 0.35rem 0.35rem;
}
.user_content_item:hover{
  cursor: pointer;
  background: #eeeeee;
}
.user_content_padding_left{
  width: 16px;
}
</style>
