<template>
  <el-container class="container">
    <el-aside  id="aside">
      <aside-view />
    </el-aside>
    <el-main id="main">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </el-main>
  </el-container>
</template>

<script>
import AsideView from '../views/Aside'
import HeaderView from '../views/Header'

import Split from 'split.js'
export default {
  components: {
    AsideView
  },
  data () {
    return {
      kk: 'asasdfdf',
      asdf: 'sdf'
    }
  },
  created () {

  },
  mounted () {
    Split(['#aside', '#main'], {
      gutterSize: 5,
      sizes: [15, 85]
    });
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
}
::v-deep .gutter {
  cursor: e-resize;
  height: 100%;
}

#aside {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
